<template>
  <div>
    <!-- passed in stuff -->
    <slot />
    <!-- Generic error handler -->
    <md-dialog :md-active.sync="show_error">
      <md-dialog-title class="md-invalid">
        Admin Application Error
      </md-dialog-title>
      <md-dialog-content>
        <div v-if="error.simple">
          {{ error.simple }}
        </div>
        <div v-if="error.response && error.response.status === 403">
          Access was denied. <a href="/login">Please try login again</a>
        </div>
        <div v-if="error.response && error.response.status === 500">
          <p>There was an error processing your request. Try again or get in contact if the problem persists</p>
          <p v-if="error.response.data && error.response.data.error && error.response.data.error.id">
            Error ID: {{ error.reponse.data.error.id }}
          </p>
        </div>
        <div v-if="error.message">
          <div v-if="error.message === 'Network Error'">
            There was a problem communicating with the ACT Law Society servers. Please try again
          </div>
          <div v-else>{{ error.message }}</div>
        </div>
        <div v-if="error.detail">
          Detail: {{ error.detail }}
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-primary"
          @click="show_error = false"
        >
          Close
        </md-button>
        <md-button
          class="md-primary"
          @click="show_error = false"
        >
          Reload App
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
// import rdebug from 'debug'
import { mapActions } from 'vuex'


import { MdButton, MdDialog } from 'vue-material/dist/components'

Vue.use(MdButton)
Vue.use(MdDialog)

export default {
  name: 'ErrorCapture',
  data() {
    return {
      error: {},
      show_error: false,
    }
  },
  methods: {
    ...mapActions({
      errorNotification: 'notifications/errorNotification',
    }),
  },
  errorCaptured(error, vm, info) {
    console.error('An Error occurred and was captured by ErrorCapture')
    console.error(error)
    console.error('error info', info)
    // This should be an alert. Handled errors go in notifications. 
    if (error.simple) {
      this.errorNotification({ message: `Oh no! ${error.simple}` })
    } else {
      this.error = error
      this.show_error = true
    }
    return false
  },
}
</script>

<style>
</style>
