<template>
  <div>

    <!-- <md-toolbar class="md-transparent" md-elevation="0">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ label }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-field v-if="filter" class="actlaw-event-search-input" md-clearable>
          <label>Search</label>
          <md-input
            placeholder="Search..."
            v-model="filter_term"
          />
        </md-field>
        <md-button class="md-icon-button md-raised">
          <md-tooltip md-direction="bottom">Refresh Data</md-tooltip>
          <md-icon>refresh</md-icon>
        </md-button>
      </div>
    </md-toolbar> -->

    <!-- <md-table-empty-state
      md-label="No users found"
    /> -->
    
    <div class="mh-data-table">
      <div 
        v-if="headings"
        class="mh-data-table-heading"
      >
        <div
          v-for="(col, index) in headings"
          v-bind:key="index"
          class="mh-data-table-head md-body-2"
        >
          {{ col }}
        </div>
      </div>
      
      <div
        v-for="(row, row_index) in values"
        v-bind:key="row_index"
        class="mh-data-table-row"
      >
        <div
          v-for="(col, col_index) in columns"
          v-bind:key="col_index"
          class="mh-data-table-cell"
        >
          <div :style="column_classes[col_index]">
            {{ row[col] }}
          </div>
        </div>
        <!-- <md-dialog
          :md-active.sync="row.show_detail"
        >
          <md-dialog-title>
            {{ row.type }}
          </md-dialog-title>
          <md-dialog-content>
            {{ row.message }}
            {{ row.metadata }}
          </md-dialog-content>
        </md-dialog> -->
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import rdebug from 'debug'
import _forEach from 'lodash/forEach'

import {
  // MdCard,
  MdButton,
  MdToolbar,
  MdTooltip,
  MdIcon,
  MdField,
  MdDialog,
} from 'vue-material/dist/components'

_forEach({
  // MdCard,
  MdButton,
  MdToolbar,
  MdTooltip,
  MdIcon,
  MdField,
  MdDialog,
}, obj => Vue.use(obj))

const debug = rdebug('actlaw:frontend-admin:page:AdminEvents')

export default {
  name: 'AdminEvents',
  props: {
    label: {
      type: String,
      default: 'Table',
    },
    headings: {
      type: Array,
      default: null,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    column_classes: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      filter_term: '',
      // currentSortOrder: 'desc',
    }
  },
  computed: {
    ...mapGetters([
      'api_instance',
    ]),
  },
  methods: {
    /**
     * Supports sort by moment date and boolean or normal string localeCompare
     * @param {array} value Array to be sorted by `this.currentSort` in `this.currentSortOrder`
     * @returns {array} Sorted array
     */
    customSort (value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          if (sortBy === 'created_at' || sortBy === 'modified_at') {
            return b[sortBy].format('YYYYMMDD') - a[sortBy].format('YYYYMMDD')
          } else if (sortBy === 'type') {
            return `${a.type} - ${a.tag}`.localeCompare(`${b.type} - ${b.tag}`)
          } else if (sortBy === 'downloaded') {
            return a || b  
          } else {
            return a[sortBy].localeCompare(b[sortBy])
          }
        }

        if (sortBy === 'created_at' || sortBy === 'modified_at') {
          return a[sortBy].format('YYYYMMDD') - b[sortBy].format('YYYYMMDD')
        } else if (sortBy === 'type') {
            return `${b.type} - ${b.tag}`.localeCompare(`${a.type} - ${a.tag}`)

        } else if (sortBy === 'downloaded') {
            return b || a
        } else {
          return b[sortBy].localeCompare(a[sortBy])
        }
      })
    },
    /**
     * Show the detail for a row
     */
    showDetail(row_index){
      Vue.set(this.values, row_index, true)
    }
  },
  async mounted() {
    debug('DataTable mounted')
    // this.getEvents()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.actlaw-event-search-input {
  width: 200pt;
}

.mh-data-table{
	display: table;
	width: 100%;
}
.mh-data-table-nowrap {
  white-space: 'nowrap';
}
.mh-data-table-row {
  border-bottom: 1px solid #ccc;
	display: table-row;
  height: 3em;
}
.mh-data-table-cell, .mh-data-table-head {
  border-bottom: 1px solid #ccc;
	display: table-cell;
  height: 3em;
  vertical-align: middle;
	padding: 11px 17px 9px 17px;
}
.mh-data-table-heading {
	/* background-color: #f1f1f1; */
	display: table-header-group;
	font-weight: bold;
}
.mh-data-table-foot {
	background-color: #EEE;
	display: table-footer-group;
	font-weight: bold;
}
.mh-data-table-body {
	display: table-row-group;
}
</style>
