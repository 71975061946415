<template>
  <div>
    <md-card>
      
      <md-toolbar  class="md-table-toolbar md-transparent" md-elevation="0">
        <h3 class="md-title">Member Add</h3>
      </md-toolbar>

      <md-card-content>
        <md-field :class="getValidationClass('username')">
          <label for="username">Username</label>
          <md-input
            id="username"
            v-model="user.username"
            autocomplete="new-password"
            @change="clearServerError"
          />
          <span class="md-error" v-if="!$v.user.username.required">Field is required</span>
          <span class="md-error" v-else-if="!$v.user.username.minLength">
            Username must have at least {{$v.user.username.$params.minLength.min}} letters.
          </span>
          <span class="md-error" v-else-if="!$v.user.username.maxLength">
            Username must have no more than {{$v.user.username.$params.maxLength.max}} letters.
          </span>
          <span class="md-error" v-else-if="!$v.user.username.alphaNumDotDashUnderscore">
            Username must only contain must contain only letters, numbers, underscore, dash or dot.
          </span>
          <span class="md-error" v-else-if="!$v.user.username.serverError">
            Username is already in use.
          </span>
        </md-field>

        <md-field :class="getValidationClass('password')">
          <label for="password">Password</label>
          <md-input id="password" v-model="user.password" type="password" autocomplete="new-password" required />
          <span class="md-error" v-if="!$v.user.password.required">Field is required</span>
          <span class="md-error" v-else-if="!$v.user.password.minLength">
            Password must have at least {{$v.user.password.$params.minLength.min}} letters.
          </span>
          <span class="md-error" v-else-if="!$v.user.password.maxLength">
            Password must have no more than {{$v.user.password.$params.maxLength.max}} letters.
          </span>
        </md-field>

        <md-field :class="getValidationClass('display_name')">
          <label for="display_name">Name</label>
          <md-input id="display_name" v-model="user.display_name" required />
          <span class="md-error" v-if="!$v.user.display_name.required">Field is required</span>
          <span class="md-error" v-else-if="!$v.user.display_name.minLength">
            Name must have at least {{$v.user.display_name.$params.minLength.min}} letters.
          </span>
          <span class="md-error" v-else-if="!$v.user.display_name.maxLength">
            Name must have no more than {{$v.user.display_name.$params.maxLength.max}} letters.
          </span>
        </md-field>

        <md-field :class="getValidationClass('email')">
          <label for="email">Email</label>
          <md-input id="email" v-model="user.email" required />
          <span class="md-error" v-if="!$v.user.email.required">Field is required</span>
          <span class="md-error" v-else-if="!$v.user.email.email">Field must be a valid email address</span>
          <span class="md-error" v-else-if="!$v.user.email.maxLength">
            Email must have at least {{$v.user.email.$params.maxLength.max}} letters.
          </span>
        </md-field>

        <br>
        <md-checkbox id="role_admin" v-model="user.role_admin">
          Is this an administrator user? This is only for ACT Law Society users
        </md-checkbox>
        
        <!-- <br>
        <md-checkbox
          id="disabled"
          v-model="user.disabled"
        >
          Disable User?
        </md-checkbox> -->

        <!-- <br>
        <md-checkbox
          id="verified"
          v-model="user.verified"
        >
          Email verified
        </md-checkbox> -->

      </md-card-content>

      <div class="actlaw-no-progress">
        <md-progress-bar v-if="is_sending" md-mode="indeterminate"></md-progress-bar>
      </div>
      <md-card-actions>
        <md-button class="md-accent" @click="$router.back()">Cancel</md-button>
        <md-button class="md-primary" @click="runUserAdd">Save</md-button>
      </md-card-actions>      
    </md-card>
    <!-- <pre>{{ user }}</pre> -->
  </div> 
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import rdebug from 'debug'
import moment from 'moment'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

const alphaNumDotDashUnderscore = function (value){
  return /^[\w.-]+$/.exec(value)
}

import {
  MdCard,
  MdButton,
  MdRipple,
  MdContent,
  MdMenu,
  MdCheckbox,
  MdProgress,
  MdRadio,
} from 'vue-material/dist/components'

const debug = rdebug('actlaw:frontend-admin:page:AdminUserEdit')
Vue.use(MdCard)
Vue.use(MdButton)
Vue.use(MdRipple)
Vue.use(MdContent)
Vue.use(MdMenu)
Vue.use(MdCheckbox)
Vue.use(MdProgress)
Vue.use(MdRadio)

debug('%s', required)
debug('%s', alphaNumDotDashUnderscore)

export default {
  name: 'AdminUserEdit',
  mixins: [validationMixin],
  data() {
    return {
      user: {
        username: '',
        password: '',
        email: '',
        display_name: '',
        disabled: false,
        verified: true,
        role_member: true,
      },
      is_sending: false,
      server_error: {
        username: false,
      }
    }
  },
  validations: {
    user: {
      username: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(64),
        alphaNumDotDashUnderscore(value){ return Boolean(/^[\w.-]+$/.exec(value)) },
        serverError() { return (this.server_error.username === false) ? true : false },
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(50),
      },
      email:{
        required,
        email,
        maxLength: maxLength(255),
      },
      display_name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(64),
      },
    },
  },
  computed: {
    ...mapGetters([
      'api_instance',
    ]),
    page_topic() {
      if (this.$route.name === 'admin_users_members') return 'members'
      if (this.$route.name === 'admin_users_public') return 'public'
      return ''
    },
  },
  methods: {
    clearServerError(){
      Vue.set(this.server_error, 'username', false)
      this.$v.user.username.$touch()
      // this.$v.touch()
    },
    serverError(field_name){
      return this.server_error[field_name]
    },
    getValidationClass (field_name) {
      const field = this.$v.user[field_name]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    ...mapActions({
      goodNotification: 'notifications/goodNotification',
      warnNotification: 'notifications/warnNotification',
    }),
    async runUserAdd() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.is_sending = true
      try {
        const res = await this.api_instance.post('/@4iradmin/users/add', this.user)
        debug('save user id[%s]', this.user.id, res)
        const user = res.data.data
        if (user.created_at) user.created_at = moment(user.created_at)
        if (user.modified_at ) user.modified_at = moment(user.modified_at)
        this.user = user
        this.goodNotification({ message: 'User added' })
        this.$router.back()
      }
      catch (error) {
        if (error.name === 'ActlawApiRemoteValidationException' && error.field) {
          this.server_error[error.field] = error.message
          return this.warnNotification({ message: error.message })
        }
        throw error
      }
      finally {
        this.is_sending = false
      }
    },
  },
  mounted() {
    debug('AdminUserEdit mounted')
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped language="scss">
</style>
