
import axios from 'axios'
import rdebug from 'debug'
import {
  ActlawApi, 
  ActlawApiException,
  ActlawApiRemoteException,
  ActlawApiRemoteValidationException,
  ActlawApiRemoteAuthenticationException
 } from '@actlaw/js-actlaw-api'

let debug = rdebug('actlaw:frontend:ActlawClientApi')
if (!debug.enabled) debug = function noop() {}


export default class ActlawClientApi extends ActlawApi {

  static async getFrontendConfig() {
    debug('getting config [GET /config/config.json]')
    const response = await axios.get('/config/config.json')
    if (!response.data) throw new Error('No data in config response')
    if (!response.data.api_url) throw new Error('No api_url in config response')
    if (!response.data.app_url) throw new Error('No app_url in config response')
    debug('got config response [GET /config/config.json]', JSON.stringify(response.data, null, 2))
    return response.data
  }

  static async getInstance() {
    const config = this.getFrontendConfig()
    const instance = new this({ url: config.api_url })
    return {
      instance,
      ...config,
    }
  }

  async getScheduleData() {
    const response = await this.get('/schedule/fields')
    return response.data.data
  }



}

export {
  ActlawApi,
  ActlawClientApi,
  ActlawApiException,
  ActlawApiRemoteException,
  ActlawApiRemoteValidationException,
  ActlawApiRemoteAuthenticationException,
}
