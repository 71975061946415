<template>
  <div>
    <md-card>
            
      <md-toolbar  class="md-table-toolbar md-transparent" md-elevation="0">
        <h3 class="md-title">Member Edit</h3>
        <span> {{ user.username }}</span>
      </md-toolbar>

      <md-card-content>
        <md-field>
          <label for="display_name">Name</label>
          <md-input id="display_name" v-model="user.display_name" />
        </md-field>
        <md-field>
          <label for="email">Email</label>
          <md-input id="email" v-model="user.email" />
        </md-field>
        
        <md-checkbox
          id="user_reset_password"
          v-model="user_reset_password"
        >
          Reset users password
        </md-checkbox>
        
        <br>

        <md-checkbox
          id="user_disabled"
          v-model="user.disabled"
        >
          Disable user
        </md-checkbox>
        
        <!-- <br>
        <md-checkbox
          id="verified"
          v-model="user.verified"
        >
          Email verified
        </md-checkbox> -->
        
        <br>
        <md-checkbox
          id="locked"
          v-model="user.locked"
        >
          Account locked
        </md-checkbox>

        <br>
        <md-checkbox
          id="role_admin"
          v-model="user.role_admin"
        >
          Admin account
        </md-checkbox>

        <div class="md-title">
          {{ saved_status }}
        </div>
      </md-card-content>

      <div class="actlaw-no-progress">
        <md-progress-bar v-if="is_sending" md-mode="indeterminate"></md-progress-bar>
      </div>
      <md-card-actions>
        <md-button class="md-primary" @click="$router.back()">Cancel</md-button>
        <md-button class="md-accent md-raised" @click="runUserSave">Save</md-button>
      </md-card-actions>      
    </md-card>
    <!-- <pre>{{ user }}</pre> -->

    <!-- <user-events :user_id="user.id" limit="20"/> -->
  </div> 
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import rdebug from 'debug'
import moment from 'moment'
import Promise from 'bluebird'

// import UserEvents from '../components/UserEvents'
import { MdCard, MdButton, MdRipple, MdContent, MdMenu, MdCheckbox, MdProgress } from 'vue-material/dist/components'

const debug = rdebug('actlaw:frontend-admin:page:AdminUserEdit')
Vue.use(MdCard)
Vue.use(MdButton)
Vue.use(MdRipple)
Vue.use(MdContent)
Vue.use(MdMenu)
Vue.use(MdCheckbox)
Vue.use(MdProgress)

export default {
  name: 'AdminUserEdit',
  components: {
    // 'user-events': UserEvents,
  },
  data() {
    return {
      user: {},
      is_sending: false,
      saved_status: '',
      user_reset_password: false,
    }
  },
  validations: {
    email:{
      required: true,
      email: true,
      maxLength: 255,
    },
    display_name: {
      required: true,
      minLength: 2,
      maxLength: 64,
    },
  },
  computed: {
    ...mapGetters([
      'api_instance',
    ]),
    page_topic() {
      if (this.$route.name === 'admin_users_members') return 'members'
      if (this.$route.name === 'admin_users_public') return 'public'
      return ''
    },
  },
  methods: {
    ...mapActions({
      goodNotification: 'notifications/goodNotification'
    }),
    async runUserSave() {
      this.is_sending = true
      const start_time = Date.now()
      try {
        debug('saving user id[%s]', this.user.id, this.user)
        const res = await this.api_instance.post(`/@4iradmin/users/id/${this.user.id}`, {
          ...this.user,
          frontend_reset_password: this.user_reset_password,
        })
        debug('saved user id[%s]', this.user.id, res.data)
        const user = res.data.data
        if (user.created_at) user.created_at = moment(user.created_at)
        if (user.modified_at ) user.modified_at = moment(user.modified_at)
        this.user = user
        this.user_reset_password = false
        this.goodNotification({ message: 'User saved' })
      }
      finally {
        const total_time = Date.now() - start_time < 250
        if (total_time > -100 && total_time < 250) {
          await Promise.delay(250 - total_time)
        }
        this.is_sending = false
        await Promise.delay(500)
        this.$router.back()
      }
    },
    async getUser(id) {
      const res = await this.api_instance.get(`/@4iradmin/users/id/${id}`)
      debug('get user id[%s]', id, res)
      const user = res.data.data
      if (user.created_at) user.created_at = moment(user.created_at)
      if (user.modified_at ) user.modified_at = moment(user.modified_at)
      this.user = user
    },
  },
  onMount() {
    debug('AdminUserEdit mounted')
  },
  async beforeMount() {
    await this.getUser(this.$route.params.user_id)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped language="scss">
</style>
