<template>
  <div class="md-layout-item md-alignment-center md-xsmall-size-100
    md-small-size-100 md-medium-size-85 md-large-size-75 md-xlarge-size-60">
    <div class="actlaw-content-bg">
      <md-toolbar md-elevation="1" class="md-accent">
        <h3 class="md-title" style="flex: 1">
          <router-link :to="{ name: 'admin_main_content' }">Admin</router-link>
          <!-- <span v-if="current_route_label !== ''">
            : <router-link :to="{ name: this.$route.name }">{{ current_route_label }}</router-link>
          </span> -->
        </h3>
        <div class="md-toolbar-section-end">
          <div class="actlaw-toolbar-name">{{ display_name }}</div>
          <md-menu md-direction="bottom-end" :md-offset-y="12" md-close-on-click  md-size="big">
            <md-button  class="md-icon-button md-raised md-primary" md-menu-trigger>
              <md-icon>more_vert</md-icon>
            </md-button>

            <md-menu-content>
              <md-list>
                <md-list-item to="/users/members">
                  Members
                </md-list-item>
                <!-- <md-list-item to="/users/public">
                  Public
                </md-list-item> -->
                <md-divider/>
                <md-list-item to="/reports">
                  Reports
                </md-list-item>
                <md-divider/>
                <md-list-item to="/events">
                  Events
                </md-list-item>
                <md-divider/>
                <md-list-item @click="logout">
                  Logout
                </md-list-item>
              </md-list>
            </md-menu-content>
          </md-menu>
        </div>
      </md-toolbar>

      <router-view/>
      
      <md-divider/>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations, mapGetters } from 'vuex'
import rdebug from 'debug'

import {
  MdMenu,
  MdIcon,
  MdCard,
  MdButton,
  MdToolbar,
  MdList,
  MdDivider,
  MdSubheader
} from 'vue-material/dist/components'

const debug = rdebug('actlaw:frontend-admin:page:AdminMain')
Vue.use(MdIcon)
Vue.use(MdMenu)
Vue.use(MdCard)
Vue.use(MdButton)
Vue.use(MdToolbar)
Vue.use(MdList)
Vue.use(MdDivider)
Vue.use(MdSubheader)

export default {
  name: 'AdminMain',
  metaInfo: {
    title: 'Admin',
  },
  computed: {
    ...mapState([
      'jwt', 
      'api_instance'
    ]),
    ...mapGetters({
      'api_instance': 'api_instance',
      display_name: 'admin/display_name',
    }),
    current_route_label() {
      switch (this.$route.name) {
        case 'admin_main': return ''
        case 'admin_users': return 'Users'
        case 'admin_users_members': return 'Users : Members'
        case 'admin_users_public': return 'Users : Public'
        case 'admin_reports': return 'Reports'
        case 'admin_users_member_edit': return 'Users : Member Edit'
        default: return ''
      }
    }
  },
  methods: {
    ...mapMutations([
      'clearJwt'
    ]),
    async logout() {
      let res = this.api_instance.post('/auth/logout')
      this.clearJwt()
      await res
    }
  },
  watch: {
    jwt(newval) {
      if (!newval) this.$router.push('/login')
    }
  },
  onMount() {
    debug('AdminMain mounted')
    if (!this.state.jwt) this.$router.push('/login')
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .md-card {
    margin-top: 5pt;
    margin-left: 5pt;
    margin-right: 5pt;
    margin-bottom: 5pt;
  }
  .actlaw-layout-main {
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
  }
  .actlaw-content-bg {
    background: #ffffff;
  }
  .actlaw-toolbar-name {
    padding-right: 10pt;
  }

  .actlaw-no-progress {
    height: 15px;
  }
</style>
