
<template>
  <div id="notifications-two">
    <div v-if="false"><div>Notification debug: {{ debug }}</div>
      <md-button class="md-primary" @click="goodNotification({ message: 'I am debug' })">I am debug</md-button>
      <md-button class="md-primary" @click="notification_is_active = true">true</md-button>
      <md-button class="md-primary" @click="notification_is_active = false">false</md-button>
    </div>
    <md-snackbar
      class="actlaw-snackbar"
      :md-active.sync="notification_is_active"
      :md-duration="notification_timeout"
    >
      <span class="actlaw-notification-message">{{ notification_message }}</span>
      <md-button
        class="md-primary"
        @click="notification_is_active = false"
      >
        Close
      </md-button>
    </md-snackbar>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { MdSnackbar, MdButton } from 'vue-material/dist/components'
import debugr from 'debug'

Vue.use(MdSnackbar)
Vue.use(MdButton)

const debug = debugr('actlaw:frontend-admin:components:NotificationTwo')

export default {
  name: 'NotificationTwo',
  computed: {
    ...mapState({
      debug: 'debug',
      all_notifications: 'notifications/all_notifications',
    }),
    notification_is_active: {
      // mapState didn't have a setter
      get() {
        return this.$store.state.notifications.notification_is_active
      },
      set(value) {
        this.$store.state.notifications.notification_is_active = value
      },
    },
    ...mapGetters({
      has_more_notifications: 'notifications/has_more_notifications',
      notification_message: 'notifications/notification_message',
      notification_color: 'notifications/notification_color',
      notification_timeout: 'notifications/notification_timeout',
      active: 'notifications/active',
    }),
  },
  watch: {
    notification_is_active(new_active) {
      if (!new_active && this.has_more_notifications) {
        debug('notification is going active', this.has_more_notifications)
        this.$store.commit('notifications/shiftNextNotification')
        this.$nextTick(() => { this.notification_is_active = true })
      }
    },
  },
  methods: {
    ...mapActions({
      goodNotification: 'notifications/goodNotification',
      warnNotification: 'notifications/warnNotification',
      errorNotification: 'notifications/errorNotification',
    }),
  },
  mounted() {
    debug('notification mounted (debug:', this.$store.state.debug)
    Vue.nextTick(() => {
      debug('this.$store.state.notifications', this.$store.state.notifications)
      if (this.$store.state.debug) this.warnNotification({ message: 'Debug is turned on' })
    })
  },
  // vuetify: new Vuetify(),
}
</script>

<style lang="scss" scoped>
</style>
