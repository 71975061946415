import Vue from 'vue'
import Vuex from 'vuex'
import rdebug from 'debug'
// import { validationMixin } from 'vuelidate'

import ActlawClientApi from '../ActlawApi'
import notification_module from './NotificationTwo'
import admin_module from './Admin'

// Vuetify
// import 'vuetify/dist/vuetify.min.css'

let debug = rdebug('actlaw:frontend-admin:store:index')
if (!debug.enabled) debug = function noop() { }

Vue.use(Vuex)

export class FormError extends Error {

  constructor(msg, metadata) {
    super(msg)
    this.field = metadata.field
    this.method = metadata.method
    this.client_msg = metadata.client_msg || 'Error in form processing'
  }

}

export const generateStoreConfig = function generateStoreConfig(overrides = {}) {

  // Store the API instance outside of vuex so it doesn't get serialized
  let api_instance = null
  if (overrides.api_instance) {
    api_instance = overrides.api_instance
  }

  const store_config = {
    // strict: true,
    modules: {
      notifications: notification_module,
      admin: admin_module,
      // errors: errorModule,
    },
    state: {
      jwt: null,
      is_awaiting_response: false,
      config_api_url: null,
      config_app_url: null,
      schedule_loaded: false,
      config_loaded: false,
      contract_reviewed: false,

      // Old errors
      error: false,

      // errors/
      errors: [],
      is_there_an_error: false,

      // app
      debug: false,
      loaded: false,
    },
    // These are the getters
    getters: {
      // Complex object
      api_instance: () => api_instance,
      error: state => state.error,
      is_there_an_error: state => Boolean(state.error),
      is_there_a_contract_id: state => Boolean(state.contract_id),
      is_awaiting_response: state => state.is_awaiting_response,
    },
    // These are the mutations
    mutations: {
      setDisplayName(state, payload) {
        state.display_name = payload
      },
      setJwt(state, payload) {
        api_instance.addJwt(payload)
        state.jwt = payload
      },
      clearJwt(state, payload) {
        api_instance.addJwt(payload)
        state.jwt = payload
      },
      setApiInstance(state, payload) {
        api_instance = payload
        debug('mutations - set api_instance', payload)
        return state
      },
      setAppURL(state, payload) {
        state.config_app_url = payload
        debug('mutations - set config_app_url', payload)
        return state
      },
      setApiURL(state, payload) {
        state.config_api_url = payload
        debug('mutations - set config_api_url', payload)
        return state
      },
      setConfigLoaded(state, payload) {
        state.config_loaded = payload
        debug('mutations - set config loaded', payload)
        return state
      },
      setLoaded(state, payload) {
        state.loaded = payload
        debug('mutations - set loaded', payload)
        return state
      },

      setError(state, payload) {
        state.error = payload
        debug('mutations - set error', payload)
        return state
      },
      clearError(state) {
        state.error = false
      },

    },
    actions: {
      async getFrontendConfig({ commit }) {
        // get data from JSON
        try {
          const data = await ActlawClientApi.getFrontendConfig()
          debug('retrieved config api_url', data.api_url)
          debug('retrieved config app_url', data.app_url)
          commit('setApiURL', data.api_url)
          commit('setAppURL', data.app_url)
          commit('setApiInstance', new ActlawClientApi({ url: data.api_url }))
          commit('setConfigLoaded', true)
          commit('setLoaded', true)
          commit('setError', false)
        } catch (error) {
          debug('getFrontendConfig error', error)
          console.error('ERROR loading config json', error)
          commit('setConfigLoaded', false)
          commit('setLoaded', true)
          commit('setError', error)
          throw error
        }
      },
    },
  }

  if (overrides) {
    debug('generateStoreConfig received overrides:', overrides)
    Object.assign(store_config, overrides)
  }
  return store_config
}

export const store_config = generateStoreConfig()

// export default new Vuex.Store({
export const store = new Vuex.Store(store_config)

//const validations = new Validations()
//store.$validations = validations
//store.validateGroups = validations.validateGroups
//debug('store.validateGroups', store.validateGroups)
export default store
