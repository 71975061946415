<template>
 <form id="login">
    <md-field :class="getValidationClass('username')">
      <label for="login_username">Username</label>
      <md-input
        id="login_username"
        v-model="username"
        v-on:keyup.enter="focusPasswordInput"
        ref="usernameInput"
        autocomplete="login_username"
        name="login_username"
      />
      <span
        v-if="username_validation === false"
        class="md-error"
      >
        {{ username_validation_message }}
      </span>
    </md-field>
    <md-field :class="getValidationClass('password')">
      <label for="login_password">Password</label>
      <md-input
        id="login_password"
        type="password"
        v-model="password"
        v-on:keyup.enter="sendLogin"
        ref="passwordInput"
        autocomplete="login_password"
        name="login_password"
      />
      <span
        v-if="password_validation === false"
        class="md-error"
      >
        {{ password_validation_message }}
      </span>
    </md-field>
    <div class="actlaw-no-progress">
      <md-progress-bar v-if="is_sending" md-mode="indeterminate"></md-progress-bar>
    </div>
    <md-card-actions>
      <md-button
        id="actlaw-login--btn-login"
        class="md-accent md-raised actlaw-login--btn-login"
        @click="sendLogin"
        ref="submitButton"
      >
        Login
      </md-button>
    </md-card-actions>
  </form>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import debugr from 'debug'
import _has from 'lodash/has'
import jwtDecode from 'jwt-decode'
import Promise from 'bluebird'
import { Exception } from '@actlaw/js-exception'

import { MdCard, MdButton, MdField } from 'vue-material/dist/components'

Vue.use(MdButton)
Vue.use(MdCard)
Vue.use(MdField)

const debug = debugr('actlaw:components:Login')

export default {
  name: 'Login',
  metaInfo: {
    title: 'Login',
  },
  data() {
    return {
      is_sending: false,
      username: null,
      username_validation: null,
      username_validation_message: null,
      password: null,
      password_validation: null,
      password_validation_message: null,
    }
  },
  computed: {
    ...mapGetters([
      'api_instance',
    ]),
  },
  methods: {
    ...mapMutations({
      setJwt: 'setJwt',
      setDisplayName: 'admin/setDisplayName',
    }),
    ...mapActions({
      errorNotification: 'notifications/errorNotification',
      warnNotification: 'notifications/warnNotification',
    }),
    getValidationClass(field_name) {
      if (this[`${field_name}_validation`] !== null && !this[`${field_name}_validation`]) {
        return {
          'md-invalid': true
        }
      }
    },
    focusPasswordInput(){
      // debugger
      return this.$refs.passwordInput.$el.focus()
    },
    focusUsernameInput(){
      return this.$refs.usernameInput.$el.focus()
    },
    validateField(field_name){
      if (field_name === 'username') {
        this.username_validation = (this.username) ? true : false
        this.username_validation_message = (this.username) ? null : 'Please enter a username'
      }
      if (field_name === 'password') {
        this.password_validation = (this.password) ? true : false
        this.password_validation_message = (this.password) ? null : 'Please enter a password'
      }
      if (!this.username_validation || !this.password_validation) return false
    },
    async sendLogin() {
      console.log('api_instance', this.api_instance)
      this.validateField('username')
      this.validateField('password')
      if (!this.username_validation || !this.password_validation) return
      this.is_sending = true
      const start_time = Date.now()
      try {
        const res = await this.api_instance.post('/auth/login', {
          username: this.username,
          password: this.password
        }, {
          withCredentials: true,
        })
        if (!res.data.data.token) {
          throw new Exception('No token in authentication data', { label: 'Authentication Error', code: '400at' })
        }
        let token_data = jwtDecode(res.data.data.token)
        if ( token_data.role_admin !== true) {
          return this.errorNotification({ message: 'Your user does not have admin rights'})
        }
        this.setJwt(res.data.data.token)
        this.setDisplayName(res.data.data.display_name)
        return this.$router.push('/')
      }
      catch (error) {
        debug('api request got error', error)
        debug('api request got error', error.name)
        if (error.name === 'ActlawApiRemoteAuthenticationException') {
          // fail auth
          this.warnNotification({ message: 'Authentication Failed' })
        }
        else if (error.name === 'ActlawApiRemoteValidationException') {
          // fail validation highlight fields
          this.warnNotification({ message: error.simple || error.message })
          if (error.field) {
            if (_has(this,`${error.field}_validation`)) {
              this[`${error.field}_validation`] = false
            }
            if (_has(this,`${error.field}_validation_message`)) {
              this[`${error.field}_validation_message`] = error.simple || error.message
            }
          }
        }
        else {
          // allow error to bubble up
          throw error
        }
      }
      finally {
        const total_time = Date.now() - start_time < 250
        if (total_time > -100 && total_time < 250) {
          await Promise.delay(250 - total_time)
        }
        this.is_sending = false
      }
    }
  },
  mounted(){
    Vue.nextTick(this.focusUsernameInput)
  },
}
</script>

<style>
</style>
