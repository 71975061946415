<template>
  <div class="actlaw-layout-main">
    <md-card>
      <md-card-header class="md-title">
        Events
      </md-card-header>  
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-xsmall-size-100 md-size-45">
            <md-datepicker v-model="filter_from_date" md-immediately>
              <label>From</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-size-45">
            <md-datepicker v-model="filter_to_date" md-immediately>
              <label>Until</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-size-10">
            <md-button class="md-icon-button md-raised md-accent" @click="getEvents">
              <md-tooltip md-direction="bottom">Refresh Data</md-tooltip>
              <md-icon>refresh</md-icon>
            </md-button>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-size-45">
            <md-field class="actlaw-event-search-type">
              <label>Type</label>
              <md-select v-model="filter_type" name="filterType" v-on:mdSelected="filterChange">
                <md-option :value="false">
                  Any
                </md-option>
                <md-option
                  v-for="(evtype, index) in event_types"
                  v-bind:key="index"
                  :value="index"
                >
                  {{ evtype.type }} - {{ evtype.tag }}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-size-45">
            <md-field class="actlaw-event-search-input" md-clearable>
              <label>Search</label>
              <md-input
                placeholder="Search..."
                v-model="filter_term"
              />
            </md-field>
          </div>

        </div>
      </md-card-content>

      <data-table 
        label="Events"
        :headings="[ 'Created', 'User/ID', 'Type', 'Message' ]"
        :columns="[ 'created_at_display' , 'user_id', 'type_tag', 'message' ]"
    
        :column_classes="[
          { 'white-space': 'nowrap' },
          { 
            'white-space': 'nowrap',
            'table-layout': 'fixed',
            width: '10em',
            overflow: 'hidden',
            'text-overflow': 'ellipsis'
          },
          { width: '9em', },
          {}
        ]"
        :values="filtered_events"
      />
    </md-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import rdebug from 'debug'
import moment from 'moment'
import _forEach from 'lodash/forEach'

import DataTable from '../components/DataTable'
import {
  MdButton,
  MdDatepicker,
  MdCard,
  MdField,
  MdIcon,
  MdMenu,
  MdList,
  MdTable,
  MdToolbar,
  MdTooltip,
} from 'vue-material/dist/components'

_forEach({
  MdButton,
  MdDatepicker,
  MdCard,
  MdField,
  MdIcon,
  MdMenu,
  MdList,
  MdTable,
  MdToolbar,
  MdTooltip,
}, Vue.use.bind(Vue))

const debug = rdebug('actlaw:frontend-admin:page:AdminEvents')

export default {
  name: 'AdminEvents',
  components: {
    'data-table': DataTable,
  },
  metaInfo: {
    title: 'Events',
  },
  data(){
    return {
      events: [],
      filtered_events: [],
      event_types: [],
      currentSort: 'created_at',
      currentSortOrder: 'desc',
      filter_term: '',
      filter_type: false,
      filter_days: 1,
      filter_from_date: moment().subtract(1, 'month').startOf('day').toDate(),
      filter_to_date: moment().add(1, 'day').startOf('day').toDate(),
      search_messages_user: '',
    }
  },
  computed: {
    ...mapGetters([
      'api_instance',
    ]),
  },
  methods: {
    /**
     * Supports sort by moment date and boolean or normal string localeCompare
     * @param {array} value Array to be sorted by `this.currentSort` in `this.currentSortOrder`
     * @returns {array} Sorted array
     */
    customSort (value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          if (sortBy === 'created_at' || sortBy === 'modified_at') {
            return b[sortBy].format('YYYYMMDD-HHmmSS') - a[sortBy].format('YYYYMMDD-HHmmSS')
          } else if (sortBy === 'type') {
            return `${a.type} - ${a.tag}`.localeCompare(`${b.type} - ${b.tag}`)
          } else if (sortBy === 'downloaded') {
            return a || b  
          } else {
            return a[sortBy].localeCompare(b[sortBy])
          }
        }

        if (sortBy === 'created_at' || sortBy === 'modified_at') {
          return a[sortBy].format('YYYYMMDD-HHmmSS') - b[sortBy].format('YYYYMMDD-HHmmSS')
        } else if (sortBy === 'type') {
            return `${b.type} - ${b.tag}`.localeCompare(`${a.type} - ${a.tag}`)

        } else if (sortBy === 'downloaded') {
            return b || a
        } else {
          return b[sortBy].localeCompare(a[sortBy])
        }
      })
    },
    filterEventsType(ev){
      return (
        ev.type === this.event_types[this.filter_type].type
        && ev.tag === this.event_types[this.filter_type].tag
      )
    },
    filterEventsTerm(ev){
      const re = new RegExp(this.filter_term)
      return (
        re.exec(ev.user_id)
        || re.exec(ev.message)
        || re.exec(ev.type_tag)
      )
    },
    async getEventTypes(){
      let res = await this.api_instance.get('/@4iradmin/events/types')
      this.event_types = res.data.data
    },
    async getEvents(){
      const params = { from: this.filter_from_date, to: this.filter_to_date }
      let res = await this.api_instance.get('/@4iradmin/events/all', {} ,{ params })
      const events = res.data.data.map(event => {
        if (event.created_at) {
          event.created_at = moment(event.created_at)
          event.created_at_display = event.created_at.format('DD/MM/YYYY HH:mm')
        }
        if (event.modified_at) {
          event.modified_at = moment(event.modified_at)
          event.modified_at_display = event.modified_at.format('DD/MM/YYYY HH:mm')
        }
        event.type_tag = `${event.type} ${event.tag}`
        return event
      })
      this.events = this.customSort(events)
      if (!this.filter_type) {
        this.clearEventFilter()
      } else {
        this.filterEvents()
      }
    },
    clearEventFilter(){
      this.filtered_events = this.events
    },
    filterEvents(){
      // Types
      if ( this.filter_type === false ) {
        this.filtered_events = this.events
      } else {
        this.filtered_events = this.events.filter(this.filterEventsType.bind(this))
      }
      
      // Free text
      if ( this.filter_term !== '' ) {
        this.filtered_events = this.filtered_events.filter(this.filterEventsTerm.bind(this))
      }
    },
    filterChange(/*val*/){
      this.filterEvents()
    },
    async getEvent(event_public_id){
      let res = await this.api_instance.get(`/@4iradmin/events/id/${event_public_id}`)
      const event = res.data.data
      if (event.created_at) event.created_at = moment(event.created_at)
      if (event.modified_at) event.modified_at = moment(event.modified_at)
    }
  },
  async mounted() {
    debug('AdminEvents mounted')
    this.getEventTypes()
    this.getEvents()
  },
  watch: {
    filter_type(newval) {
      this.filterChange(newval)
    },
    filter_term(newval) {
      this.filterChange(newval)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.actlaw-event-search-input {
  /* width: 200pt; */
}
.actlaw-event-search-type {
  /* width: 200pt; */
}
</style>
