<template>
  <div>

    <md-table v-model="filtered_members" md-sort="username" md-sort-order="asc"  md-card>

      <md-table-toolbar>
        <h3 class="md-title">Members</h3>
        <div class="md-toolbar-section-end">
          <md-button class="md-icon-button md-raised md-accent" to="add">
            <md-tooltip md-direction="bottom">Add Member</md-tooltip>
            <md-icon>person_add</md-icon>
          </md-button>
          <md-button class="md-icon-button md-raised md-accent" @click="getUsersMembers">
            <md-tooltip md-direction="bottom">Refresh Members</md-tooltip>
            <md-icon>refresh</md-icon>
          </md-button>
        </div>
      </md-table-toolbar>

      <!-- <md-table-empty-state
        md-label="No users found"
      /> -->

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <!-- <md-table-cell md-label="ID" md-sort-by="name">
          {{ item.id }}
        </md-table-cell> -->
        <md-table-cell md-label="Username" md-sort-by="username">
          {{ item.username }}
        </md-table-cell>
        <md-table-cell md-label="Name" md-sort-by="display_name">
          {{ item.display_name }}
        </md-table-cell>
        <md-table-cell md-label="Email" md-sort-by="email">
          {{ item.email }}
        </md-table-cell>
        <!-- <md-table-cell class="md-small-hide" md-label="Created" nowrap>
          {{ item.created_at.format('DD/MM/YYYY HH:mm') }}
        </md-table-cell> -->
        <md-table-cell md-label="Status" md-sort-by="state" nowrap>
          <div v-if="item.state === 'Disabled'" class="md-invalid">{{ item.state }}</div>
          <div v-else-if="item.state === 'Locked'" class="md-invalid">{{ item.state }}</div>
          <div v-else>{{ item.state }}</div>
        </md-table-cell>
        <!-- <md-table-cell md-label="Disabled" md-sort-by="disabled" nowrap>
          <md-checkbox disabled v-model="item.disabled" />
        </md-table-cell> -->
        <!-- <md-table-cell md-label="Locked" nowrap md-sort-by="locked">
          <md-checkbox disabled v-model="item.locked" />
        </md-table-cell> -->
        <md-table-cell md-label="Actions">
          <md-button md-menu-trigger class="md-icon-button md-raised" @click="goEditUser(item.id)">
            <md-tooltip md-direction="bottom">Edit {{ item.username }}</md-tooltip>
            <md-icon>edit</md-icon>
          </md-button>
          <!-- <md-menu md-direction="bottom-start">
            <md-button md-menu-trigger class="md-icon-button md-raised">
              <md-tooltip md-direction="bottom">Edit {{ item.username }}</md-tooltip>
              <md-icon>edit</md-icon>
            </md-button>

            <md-menu-content>
              <md-menu-item @click="goEditUser(item.id)">Edit</md-menu-item>
              <md-menu-item v-if="item.disabled === false" @click="runDisableUser(item.id)">Disable</md-menu-item>
              <md-menu-item v-else-if="item.disabled === true" @click="runEnableUser(item.id)">Enable</md-menu-item>
              <md-menu-item @click="runResetPassword(item.id)">Reset Password</md-menu-item>
            </md-menu-content>
          </md-menu> -->
        </md-table-cell>
      </md-table-row>
    </md-table>

  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import rdebug from 'debug'
import moment from 'moment'

import {
  MdCard,
  MdButton,
  MdTable,
  MdRipple,
  MdContent,
  MdMenu,
  MdList,
  MdEmptyState,
  MdTooltip,
} from 'vue-material/dist/components'

Vue.use(MdCard)
Vue.use(MdButton)
Vue.use(MdTable)
Vue.use(MdRipple)
Vue.use(MdContent)
Vue.use(MdMenu)
Vue.use(MdList)
Vue.use(MdEmptyState)
Vue.use(MdTooltip)
const debug = rdebug('actlaw:frontend-admin:page:AdminUsersMembers')

export default {
  name: 'AdminUsersMembers',
  data() {
    return {
      members: [],
      filtered_members: [],
    }
  },
  computed: {
    ...mapGetters([
      'api_instance',
    ]),
    page_topic() {
      if (this.$route.name === 'admin_users_members') return 'members'
      if (this.$route.name === 'admin_users_public') return 'public'
      return ''
    },
    filterFn() {
      if (this.$route.name === 'admin_users_members') return member => (member.role_member || member.role_admin)
      if (this.$route.name === 'admin_users_public') return member => member.role_public
      return ()=> false
    },
  },
  methods: {
    goEditUser(id) {
      return this.$router.push(`edit/${id}`)
    },
    async getUsersMembers() {
      const res = await this.api_instance.get('/@4iradmin/users/all')
      debug('users members', res)
      this.members = res.data.data
        .map(member => {
          if (member.created_at) member.created_at = moment(member.created_at)
          if (member.modified_at) member.modified_at = moment(member.modified_at)
          if (member.disabled === true) {
            member.state = 'Disabled'
          }
          else if (member.locked === true) {
            member.state = 'Locked'
          }
          else if (member.verified === false) {
            member.state = 'Unverified'
          }
          else if (member.role_admin === true) {
            member.state = 'Admin'
          }
          else if (member.role_member === true) {
            member.state = 'Member'
          }
          else if (member.role_public === true) {
            member.state = 'Public'
          }
          else {
            member.state = ''
          }
          return member
        })
      this.filtered_members = this.members.filter(this.filterFn)
    },
  },
  watch: {
    $route(){
      this.filtered_members = this.members.filter(this.filterFn)
    },
  },
  mounted() {
    debug('AdminUsersMembers mounted')
  },
  async beforeMount() {
    await this.getUsersMembers()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped language="scss">
div.md-invalid {
  color: red
}
</style>
