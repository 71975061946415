import rdebug from 'debug'

const debug = rdebug('actlaw:frontend-admin:store:Admin')

const admin_module = {
  namespaced: true,
  state: {
    display_name: null,
  },
  mutations: {
    setDisplayName(state, payload_name) {
      debug('setDisplayName', payload_name)
      state.display_name = payload_name
    },
  },
  getters: {
    display_name(state) {
      return state.display_name
    },
  },
  actions: {
  },
}

export default admin_module
