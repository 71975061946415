// import Vue from 'vue/dist/vue.js'
import Vue from 'vue'
import Router from 'vue-router'
import Meta from "vue-meta"
import debugr from "debug";

import store from '../store'

import Login from '@/components/Login.vue'
import AdminMain from '@/pages/AdminMain.vue'
import AdminMainContent from '@/pages/AdminMainContent.vue'
import AdminUsers from '@/pages/AdminUsers.vue'
import AdminUsersMembers from '@/pages/AdminUsersMembers.vue'
import AdminUserEdit from '@/pages/AdminUserEdit.vue'
import AdminUserAdd from '@/pages/AdminUserAdd.vue'
import AdminReports from '@/pages/AdminReports.vue'
import AdminEvents from '@/pages/AdminEvents.vue'

const debug = debugr('actlaw:frontend-admin:router')
Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  routes: [
    {
      path: '/',
      //name: 'admin_main',
      component: AdminMain,
      children: [
        {
          name: 'admin_main_content',
          path: '',
          component: AdminMainContent,
        },
        {
          name: 'admin_users',
          path: 'users',
          component: AdminUsers,
          children: [
            {
              name: 'admin_users_members',
              path: 'members',
              component: AdminUsersMembers,
            },
            {
              name: 'admin_users_public',
              path: 'public',
              component: AdminUsersMembers,
            },
            {
              name: 'admin_users_member_edit',
              path: 'edit/:user_id',
              component: AdminUserEdit,
            },
            {
              name: 'admin_users_member_add',
              path: 'add',
              component: AdminUserAdd,
            }
          ]
        },
        {
          name: 'admin_reports',
          path: 'reports',
          component: AdminReports,
        },
        {
          name: 'admin_events',
          path: 'events',
          component: AdminEvents,
        },
      ],
      meta: {
        requires_auth: true,
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        requires_auth: false,
      }
    },
    {
      path: '**',
      name: 'error',
      component: Vue.component('error', { template: '<div>Not found</div>'}),
      meta: {
        requires_auth: false,
      }
    },
  ],
})

router.beforeEach(( to, from, next ) => {
  if(to.meta && to.meta.requires_auth !== false) {
    debug('checking auth', to.name, store.state.jwt)
    if (!store.state.jwt) return next('/login')
  }
  return next()
})

export default router
