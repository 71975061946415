<template>
  <router-view/>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import rdebug from 'debug'

import { MdCard, MdButton, MdToolbar } from 'vue-material/dist/components'

const debug = rdebug('actlaw:frontend-admin:page:AdminUsers')
Vue.use(MdCard)
Vue.use(MdButton)
Vue.use(MdToolbar)

export default {
  name: 'AdminUsers',
  metaInfo: {
    title: 'Members',
  },
  computed: {
    ...mapState(['jwt', 'api_instance']),
    current_route_label() {
      switch (this.$route.name) {
        case 'admin_main': return ''
        default: return ''
      }
    }
  },
  methods: {
    ...mapMutations(['clearJwt']),
    async logout() {
      let res = this.api_instance.post('/auth/logout')
      this.clearJwt()
      await res
    }
  },
  watch: {
    jwt(newval) {
      if (!newval) this.$router.push('/login')
    }
  },
  onMount() {
    debug('AdminUsers mounted')
    if (!this.state.jwt) this.$router.push('/login')
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
