import rdebug from 'debug'
import _has from 'lodash/has'
import _sample from 'lodash/sample' 


const debug = rdebug('actlaw:frontend-admin:store:NotificationTwo')
const sampler = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

export class NotificationTwo {

  static generateRid(N) {
    return Array.from({ length: N }, ()=> _sample(sampler))
      .join('')
  }

  constructor(message, options) {
    this.ts = Date.now()
    this.rid = this.constructor.generateRid(5)
    this.id = `${this.ts}-${this.rid}`

    // Message for the snack
    this.message = message

    // How many ms should the snack be viewed
    this.timeout = 0

    // Is this active
    this.color = true

    if (options) {
      if (options.timeout) this.timeout = options.timeout
      if (options.color) this.color = options.color
    }

  }

}

export class GoodNotification extends NotificationTwo {

  constructor(message, options) {
    super(message, options)
    this.color = 'primary'
    if (!_has(options, 'timeout')) this.timeout = 5000
  }

}

export class WarningNotification extends NotificationTwo {

  constructor(message, options) {
    super(message, options)
    this.color = 'orange'
    if (!_has(options, 'timeout')) this.timeout = 10000
  }

}

export class ErrorNotification extends NotificationTwo {

  constructor(message, options) {
    super(message, options)
    this.color = 'accent'
    if (!_has(options, 'timeout')) this.timeout = Infinity
  }

}

const notification_module = {
  namespaced: true,
  state: {
    notification_message: '',
    notification_color: 'primary',
    notification_timeout: 1000,
    all_notifications: [],
    notification_is_active: false,
  },
  mutations: {
    addNotification(state, note) {
      debug('addNotification', note)
      if (typeof note !== 'object') throw new Error(`Notification add failed [${note}]`)
      state.all_notifications.push(note)

      if (!state.notification_is_active) {
        const new_note = state.all_notifications.shift()
        state.notification_message = new_note.message
        state.notification_color = new_note.color
        state.notification_timeout = new_note.timeout
        state.notification_is_active = true
      }
    },
    shiftNextNotification(state) {
      const new_note = state.all_notifications.shift()
      state.notification_message = new_note.message
      state.notification_color = new_note.color
      state.notification_timeout = new_note.timeout
      // state.notification_is_active = true
      return state
    },
    clearCurrentNotification(state) {
      debug('clearCurrentNotification', state.all_notifications[0])
      state.all_notifications.shift()
      return state
    },
    clearNotifications(state) {
      state.all_notifications = []
      return state
    },
  },
  getters: {
    active(state) {
      return state.notification_is_active
    },
    notification_message(state) {
      return state.notification_message
    },
    notification_color(state) {
      return state.notification_color
    },
    notification_timeout(state) {
      return state.notification_timeout
    },
    current_notification(state) {
      return state.all_notifications[0]
    },
    has_more_notifications(state) {
      return state.all_notifications.length > 0
    },
  },
  actions: {
    goodNotification({ commit }, payload) {
      // `state` is the local module state
      const note = new GoodNotification(payload.message, { ...payload })
      commit('addNotification', note)
    },
    warnNotification({ commit }, payload) {
      // `state` is the local module state
      const note = new WarningNotification(payload.message, { ...payload })
      commit('addNotification', note)
    },
    errorNotification({ commit }, payload) {
      // `state` is the local module state
      const note = new ErrorNotification(payload.message, { ...payload })
      commit('addNotification', note)
    },
  },
}

export default notification_module
