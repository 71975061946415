<template>
  <div id="app">
    <error-capture>
      <div class="md-layout md-gutter md-alignment-top-center">
          
          <!-- Loading view -->
          <div
            v-if="config_loaded === false && !error"
            class="md-layout-item md-alignment-center md-xsmall-size-100
    md-small-size-100 md-medium-size-85 md-large-size-75 md-xlarge-size-60"
          >
            <md-card>
              <md-card-header>
                <div class="md-headline">
                  Loading...
                </div>
              </md-card-header>
              <md-card-content>
                <div class="actlaw-loading">
                  <md-progress-spinner
                    md-mode="indeterminate"
                    :md-diameter="50"
                  />
                  <div v-if="config_loaded === false">
                    config
                  </div>
                  <div v-else-if="config_loaded === true">
                    forms
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
          
          <!-- Default router handler -->
          <router-view v-else-if="config_loaded === true" />

          <!-- Fix this for load errors/timeouts -->
          <div
            v-else
            class="md-layout-item md-alignment-center md-xsmall-size-100
              md-small-size-100 md-medium-size-85 md-large-size-75 md-xlarge-size-60"
          >
            <md-card>
              <md-card-header>
                <div class="md-headline md-invalid">
                  Problem loading application
                </div>
              </md-card-header>
              <md-card-content>
                <div></div>
                <div v-if="error.simple">{{ error.simple }}</div>
                <div v-if="error.message">{{ error.message }}</div>
              </md-card-content>
            </md-card>
          </div>
          <!-- <notification/> -->
      </div>
      <notification-two />
    </error-capture>
  </div>
</template>

<script>
import Vue from 'vue'
import { MdCard, MdProgress } from 'vue-material/dist/components'
import rdebug from 'debug'
import { mapActions, mapState } from 'vuex'

import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import 'typeface-roboto'

import router from './router'
import store from './store'

import ErrorCapture from '@/components/ErrorCapture.vue'
import NotificationTwo from '@/components/NotificationTwo.vue'

const debug = rdebug('actlaw:frontend-admin:App')
debug(router)

Vue.use(MdCard)
Vue.use(MdProgress)


export default {
  name: 'App',
  store,
  router,
  components: {
    'error-capture': ErrorCapture,
    'notification-two': NotificationTwo,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Online Contracts Admin',
    // all titles will be injected into this template
    titleTemplate: '%s | Online Contracts',
    meta: [
      { name: 'robots', content: 'noindex' }
    ],
  },
  computed: {
    ...mapState(['config_loaded', 'error']),
  },
  async beforeMount() {
    this.$material.locale.dateFormat = 'dd/MM/yyyy'
    await this.getFrontendConfig()
  },
  methods: {
    ...mapActions({
      getFrontendConfig: 'getFrontendConfig',
      errorNotification: 'notifications/errorNotification',
    }),
  },
  errorCaptured: (error, vm, info) => {
    // alert('An Error occurred and wasn't captured by App', error)
    console.error('Unhandled App Error!', error)
    console.error('Unhandled App Error info', info)
    const alert_error_msg = `Oh no! Something has gone really wrong.\n\n`
      +`Please report the following error:\n${error.stack}`
    alert(alert_error_msg)
    return false
  },
}
</script>

<style lang="scss">

@import "~vue-material/dist/theme/engine"; // Import the theme engine

@include md-register-theme("default", (
  // primary: #76634e, // The primary color of your application
  // primary: #ec9d28, // The primary color of your application
  accent:  #ec9d28,  // The accent or secondary color
  primary: #76634e,
  // accent: md-get-palette-color(black, 500),
  background: md-get-palette-color(grey, 100),
  theme: light
));

@import "~vue-material/dist/theme/all"; // Apply the theme

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.actlaw-loading {
  justify-content: center;
  width: 100%;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/font/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}

</style>
