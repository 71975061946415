<template>
  <div>
    <md-card>
      <md-card-header class="md-title">
        Reports
      </md-card-header>  
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-xsmall-size-100 md-size-40">
            <md-field>
              <label for="report_year">Year</label>
              <md-select v-model="report_year"  name="report_year" id="report_year">
                <md-option value="2024">2024</md-option>
                <md-option value="2023">2023</md-option>
                <md-option value="2022">2022</md-option>
                <md-option value="2021">2021</md-option>
                <!-- <md-option value="2020">2020</md-option>
                <md-option value="2019">2019</md-option> -->
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-size-40">
            <md-field>
              <label for="report_month">Month</label>
              <md-select v-model="report_month" name="report_month" id="report_month">
                <md-option value="1">January</md-option>
                <md-option value="2">February</md-option>
                <md-option value="3">March</md-option>
                <md-option value="4">April</md-option>
                <md-option value="5">May</md-option>
                <md-option value="6">June</md-option>
                <md-option value="7">July</md-option>
                <md-option value="8">August</md-option>
                <md-option value="9">September</md-option>
                <md-option value="10">October</md-option>
                <md-option value="11">November</md-option>
                <md-option value="12">December</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-size-15 actlaw-heading-button">
            <div class="md-layout">
              <div class="md-layout-item  md-size-50">
                <md-button
                  class="md-icon-button md-raised"
                  :href="adminReportPurchasedownloadUrl()"
                  target="_blank"
                >
                  <md-tooltip md-direction="bottom">Download this report</md-tooltip>
                  <md-icon>cloud_download</md-icon>
                </md-button>
              </div>
              <div class="md-layout-item  md-size-50">
                <md-button class="md-icon-button md-raised" @click="getReport">
                  <md-tooltip md-direction="bottom">Refresh Report</md-tooltip>
                  <md-icon>refresh</md-icon>
                </md-button>
              </div>
            </div>
          </div>
        </div>
        <div>
          
          <md-card>
            <md-card-header class="md-title">
              {{ formatted_month }} {{ formatted_year }} totals
            </md-card-header>
            <md-card-content>
              <div>Total Purchases: {{ count_purchases_total }}</div>
              <div>Cancelled Purchases: {{ count_purchases_cancelled }}</div>
              <div>Total contracts: {{ count_contracts_total }}</div>
              <div>Generated contracts: {{ count_contracts_generated }}</div>
              <div>Failed contracts: {{ count_contracts_failed }}</div>
            </md-card-content>
          </md-card>

          <md-table md-card v-model="user_counts">
            <md-table-toolbar class="md-title">
              Members
            </md-table-toolbar>  
            
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name">{{ item.username }}</md-table-cell>
              <!-- <md-table-cell md-label="Purchase Count" md-numeric>{{ item.purchase_count }}</md-table-cell> -->
              <md-table-cell md-label="Contract Count" md-numeric>{{ item.contract_count }}</md-table-cell>
              <!-- <md-table-cell md-label="$" md-numeric>${{ item.contract_value }}</md-table-cell> -->
            </md-table-row>

          </md-table>

        </div>
        <!-- <pre>{{ report_values }}</pre> -->
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import rdebug from 'debug'
import moment from 'moment'
import {
  MdCard,
  MdRipple,
  MdContent,
  MdMenu,
  MdList,
  MdButton,
  MdToolbar,
  MdField,
  MdTable,
  MdIcon,
} from 'vue-material/dist/components'

const debug = rdebug('actlaw:frontend-admin:page:AdminReports')
Vue.use(MdCard)
Vue.use(MdRipple)
Vue.use(MdContent)
Vue.use(MdMenu)
Vue.use(MdList)
Vue.use(MdButton)
Vue.use(MdToolbar)
Vue.use(MdField)
Vue.use(MdTable)
Vue.use(MdIcon)

const last_month = moment().subtract(1, 'month')

const current_year = Number(moment().format('YYYY'))
const running_years = []
if (!current_year || current_year < 2020 || current_year > 2040) {
  running_years.push('2026')
  running_years.push('2025')
  running_years.push('2024')
  running_years.push('2023')
  running_years.push('2022')
  running_years.push('2021')
}
for (let year = current_year; year > 2020; year--) {
  running_years.push(current_year)
}

export default {
  name: 'AdminReports',
  metaInfo: {
    title: 'Reports',
  },
  computed: {
    ...mapGetters([
      'api_instance',
    ]),
    ...mapState([
      'config_api_url',
    ]),
    current_year: ()=>current_year,
    running_years: ()=>running_years,
  },
  data(){
    return {
      report_month: last_month.format('M'),
      report_year: last_month.format('YYYY'),
      year: 0,
      month: 0,
      count_purchases_total: 0,
      count_purchases_cancelled: 0,
      count_contracts_total: 0,
      count_contracts_generated: 0,
      count_contracts_failed: 0,
      user_counts: [],
      formatted_year: null,
      formatted_month: null,
    }
  },
  methods: {
    adminReportPurchasedownloadUrl(){
      return `${this.config_api_url}`
        + `/@4iradmin/download/reports/contract-for-sale/excel?year=${this.report_year}&month=${this.report_month}`
    },
    async getReport(){
      const res = await this.api_instance.get(
        `/@4iradmin/reports/contract-for-sale/${this.report_year}/${this.report_month}`
      )
      
      // reset everything in case we hit an error halfway through
      this.count_purchases_total = 0
      this.count_purchases_cancelled = 0
      this.count_contracts_total = 0
      this.count_contracts_generated = 0
      this.count_contracts_failed = 0
      this.user_counts = []

      const data = res.data.data

      this.year = data.year
      this.month = data.zero_month
      const report_moment = moment({ year: this.year, month: this.month })
      this.formatted_year = report_moment.format('YYYY')
      this.formatted_month = report_moment.format('MMMM')

      this.count_purchases_total = data.count_purchases_total
      this.count_purchases_cancelled = data.count_purchases_cancelled
      this.count_contracts_total = data.count_contracts_total
      this.count_contracts_generated = data.count_contracts_generated
      this.count_contracts_failed = data.count_contracts_failed
      this.user_counts = data.user_counts

    } 
  },
  watch: {
    report_year(){
      this.getReport()
    },
    report_month(){
      this.getReport()
    }
  },
  async mounted() {
    debug('AdminReports mounted')
    this.getReport()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
