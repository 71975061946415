<template>
  <div>
    <div class="md-layout actlaw-layout-main">
      <div class="md-layout-item actlaw-layout-item-main">
        <md-card class="actlaw-card">
          <md-card-header class="md-title">
            Members
          </md-card-header>
          <md-card-content class="actlaw-card-content md-xsmall-size-100 md-small-size-50">
            View and edit existing members or add new users.
          </md-card-content>
          <md-card-actions>
            <md-button
              class="md-accent md-raised"
              to="/users/members"
            >
              Open
            </md-button>
          </md-card-actions>
        </md-card>
      </div>

      <div class="md-layout-item actlaw-layout-item-main md-xsmall-size-100 md-small-size-50">
        <md-card class="actlaw-card">
          <md-card-header class="md-title">
            Reports
          </md-card-header>
          <md-card-content class="actlaw-card-content">
            Member purchase reports
          </md-card-content>
          <md-card-actions>
            <md-button
              class="md-accent md-raised"
              to="/reports"
            >
              Open
            </md-button>
          </md-card-actions>
        </md-card>
      </div>

      <div class="md-layout-item actlaw-layout-item-main md-xsmall-size-100 md-small-size-50">
        <md-card class="actlaw-card">
          <md-card-header class="md-title">
            Events
          </md-card-header>
          <md-card-content class="actlaw-card-content">
            View system events
          </md-card-content>
          <md-card-actions>
            <md-button
              class="md-accent md-raised"
              to="/events"
            >
              Open
            </md-button>
          </md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import rdebug from 'debug'

import {
  MdCard,
  MdButton,
} from 'vue-material/dist/components'

Vue.use(MdCard)
Vue.use(MdButton)

const debug = rdebug('actlaw:frontend-admin:page:AdminMain')

export default {
  name: 'AdminMainContent',
  computed: {
  },
  methods: {
  },
  watch: {
  },
  mounted() {
    debug('AdminMainContent mounted')
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.actlaw-card-content {
  height: 15pt;
}
</style>
